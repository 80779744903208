import React from "react"
import ListViewSearch from "./ListViewSearch";
import ListViewLooks from "./ListViewLooks";
export default function ListViewMenu(props) {
  
  let search = null;

  if(props.data.category !== "kanban"){
    search = <ListViewSearch data={props.data.filter} module={props.data.modul} prefix={props.prefix} />;
  }

  return (
    <div className="listViewActions">
      <ListViewLooks data={props.data.savedSearch} hasAssignedUser={props.data.hasAssignedUser} module={props.data.modul} prefix={props.prefix} />
      {search}
    </div>
  );
}
