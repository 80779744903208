import React from "react";
import PureComponent from "../pure";

import sAction from "sAction";

class KanbanItemInfo extends PureComponent {

    render(){


        const data = this.props.data;

        return <div>
            <div className = "kanbanItemInfoRow">
                <div className="kanbanItemInfoLabel">{sAction.translate("LBL_ASSIGNED_TO_NAME", "acm_tescan_orders")}:</div>
                <div>{data.get("user")}</div>
            </div>
            <div className = "kanbanItemInfoRow">
                <div className="kanbanItemInfoLabel">{sAction.translate("LBL_CODE_TYPE", "acm_tescan_orders")}:</div>
                <div>{sAction.app_strings.code_type_list[data.get("code_type")]}</div>
            </div>
            <div className = "kanbanItemInfoRow">
                <div className="kanbanItemInfoLabel">{sAction.translate("LBL_COMM_LANG", "Accounts")}:</div>
                <div>{sAction.app_strings.comm_lang_list[data.get("comm_lang")]}</div>
            </div>
            <div className = "kanbanItemInfoRow">
                <div className="kanbanItemInfoLabel">{sAction.translate("LBL_DEADLINE", "acm_tescan_orders")}:</div>
                <div>{data.get("deadline")}</div>
            </div>
            <div className = "kanbanItemInfoRow">
                <div className="kanbanItemInfoLabel">{sAction.translate("LBL_THICKNESS", "acm_tescan_orders")}:</div>
                <div>{data.get("thickness")}</div>
            </div>
        </div>;
    }
}
export default KanbanItemInfo;