export default function accCreateTescanOrder(data) {

  this.detailPredefinedFields = [];
  //nazev firmy
  this.detailPredefinedFields.push({
    type: "relate_simple",
    fieldName: "accounts_acm_tescan_orders_1_name",
    name: "accounts_acm_tescan_orders_1accounts_ida",
    value: { id: data.id, "name": data.name }
  });

  // //faze prilezitosti => sondovani
  // this.detailPredefinedFields.push({
  //   type: "field",
  //   name: "sales_stage",
  //   value: "Prospecting"
  // });
  this.detailNewRecord({
    module: "acm_tescan_orders",
    way: data.prefix
  });
}
