import detailDefault from "../../detailDefault";

export default class detailContacts extends detailDefault {
  load(sAction, data) {
    this.updateDetailApiVisibility(sAction, data);
    if (sAction.dataGet(data.prefix + '/type') === 'popup') {
      this.autoFillAddress(sAction, data);
    }
  }

  update(sAction, data) {
    switch (data.field) {
      case "primary_address_country":
        this.updateDetailApiVisibility(sAction, data)
        break;
      case 'last_name':
      case 'email1':
          data[data.field] = data.value;
        this.checkDuplicity(sAction, data);
        break;
    }
  }

  //fce skryva/odkryva detail fieldy podle zeme, jestli je zeme CZECH REPUBLIC
  updateDetailApiVisibility(sAction, data) {
    let address;
    if (data.value) {
      address = data.value;
    } else {
      address = sAction.dataGet(data.prefix + "/fields/primary_address_country/value");
    }

    const updateFieldApiValue = (fieldName, fieldValue) => {
      if (sAction.dataGet(data.prefix + "/fields/"+fieldName) !== undefined) {
        sAction.dsAdd("set", data.prefix + "/fields/"+fieldName+"/def/showDetailApi", fieldValue);
      }
    }

    sAction.dsClear();
    if (!address || address === "CZECH REPUBLIC") {
      //zobrazi ikony
      updateFieldApiValue("primary_address_street", "ApiStreet");
      updateFieldApiValue("primary_address_postalcode", "ApiPSC");
      updateFieldApiValue("alt_address_street", "ApiStreet");
      updateFieldApiValue("alt_address_postalcode", "ApiPSC");
    } else {
      //skryje ikony
      updateFieldApiValue("primary_address_street", "");
      updateFieldApiValue("primary_address_postalcode", "");
      updateFieldApiValue("alt_address_street", "");
      updateFieldApiValue("alt_address_postalcode", "");
    }
    sAction.dsProcess();
  }

  autoFillAddress(sAction, data) {
    sAction.dsClear();
    // Primary address
    var fieldData = sAction.dataGet('view/fields');

    const fieldsToReplace = [
      //co se vezme => kam zapise
      ['billing_address_street', 'primary_address_street'],
      ['billing_address_city', 'primary_address_city'],
      ['billing_address_postalcode', 'primary_address_postalcode'],
      ['billing_address_state', 'primary_address_state'],
      ['billing_address_kraj', 'primary_address_kraj'],
      ['billing_address_country', 'primary_address_country'],

      ['shipping_address_street', 'alt_address_street'],
      ['shipping_address_city', 'alt_address_city'],
      ['shipping_address_postalcode', 'alt_address_postalcode'],
      ['shipping_address_state', 'alt_address_state'],
      ['shipping_address_kraj', 'alt_address_kraj'],
      ['shipping_address_country', 'alt_address_country'],
    ];

    fieldsToReplace.forEach(field => {
      const replacement = fieldData.getIn([field[0], "value"]);
      if (replacement) {
        if (sAction.dataGet(data.prefix + "/fields/"+field[1]) !== undefined) {
          sAction.dsAdd("set", data.prefix + "/fields/" + field[1] + "/value", replacement);
          sAction.dsAdd("set", data.prefix + "/changes/fields/" + field[1], replacement);
        }
      }
    });

    sAction.dsProcess();
  }

    checkDuplicity(sAction, data) {
        if (data.email1 === undefined) {
            data.email1 = sAction.dataGet(data.prefix + '/fields/email1/value');
        }

        if (data.last_name === undefined) {
            data.last_name = sAction.dataGet(data.prefix + '/fields/last_name/value');
        }

        if (!data.last_name || !data.email1) {
            return;
        }

        let searchData = {};
        searchData.recordsCount = true;
        searchData.filter = {
            operator: 'and',
            operands: [
                {
                    field: 'last_name',
                    type: 'eq',
                    value: data.last_name,
                },
                {
                    field: 'email1',
                    type: 'eq',
                    value: data.email1,
                },
            ],
        };
        searchData.function = {
            id: {
                field: "id",
                function:"count",
                module: 'Contacts'
            }
        }
        searchData.columns = [
            {
                field: "id",
                function: "count",
                module: 'Contacts',
                width:"100%",
            }
        ]
        searchData.order =  [
            {
                field:"id",
                sort:"DESC",
                module:'Contacts',
            }
        ]
        sAction.rest.post("list/Contacts", searchData, returnData => {
            if (returnData?.records[0].id && returnData?.records[0].id != 0) {
                sAction.error(sAction.translate('LBL_CONTACTS_ALREADY_EXISTS', 'Contacts'));
            }
        }, false);
    }
}
