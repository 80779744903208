import React from "react";
import PureComponent from "../../pure";

import DetailViewField from "../../detailView/DetailViewField";

import ButtonFloat from "../../formElements/Button";
import  sAction  from "sAction";
import { CompassCalibrationOutlined } from "@material-ui/icons";

export default class MassUpdateContent extends PureComponent {
  renderField(prefix, module, field, key) {
    return (
      <DetailViewField
        prefix={prefix}
        module={module}
        way={prefix + "/fields/" + field.get("name")}
        //count={fields.size}
        key={key}
        index={key}
        //rowWay={way}
        data={field}
        //id={id}
      />
    );
  }

  renderRow(row, key) {
    var renderFields = [];
    row.forEach(field => {
      renderFields.push(field);
    });

    return (
      <div key={key} className="detailViewTabContentRow">
        {renderFields}
      </div>
    );
  }

  orderFields(fields, module){
    let pomFields = [];

    fields.forEach((field, key) => {
      pomFields.push(field);
    })

    /*
    let sortArray = [
      "ss", "stage", "billing_address_department", "comm_lang", "SuspendedAsPerson", "billing_address_state", "billing_address_country",
      "clinic", "account_type", "shipping_address_state", "shipping_address_country", "Warning", "assigned_user_id", "billing_address_kraj"
    ];*/

    let sortArray = [];
    
    if(module == 'Accounts'){
      sortArray = [
        "billing_address_state", "shipping_address_state", 
        "billing_address_kraj", "shipping_address_kraj", 
        "billing_address_country", "shipping_address_country", 
        "account_type", "billing_address_department", , 
        "comm_lang", "clinic",  
        "Warning", "SuspendedAsPerson",
        "stage",
        "assigned_user_id", "ss"
      ];
    }
    else if(module == 'acm_doctors'){
      sortArray = ["title_before", "title_after",
      "title", "do_not_call",
      "active", "assigned_user_id",
    ];
    }
    else if(module == 'acm_tescan_orders'){
      sortArray = [
      "accounts_acm_tescan_orders_1_name",  "accounts_acm_tescan_orders_2_name",  
      "acm_doctors_acm_tescan_orders_1_name", "saler",
      "surgery_date", "send_date", 
      "subtype", "valid_status",
      "thickness", "ss"
    
      ];
    }
    

    pomFields.sort((fst, sec) => {
      let fstInd = sortArray.indexOf(fst.name);
      let secInd = sortArray.indexOf(sec.name);

      if(fstInd > secInd && secInd != -1){
        return 1;
      }

      return -1;
    });

    return pomFields;

  }

  render() {
    let fields = this.props.data.fields;
    const module = this.props.data.module;
    const prefix = this.props.data.prefix;

    const changed =
      this.props.data.changes.get("fields").size > 0 ? true : false;

    var row = [];
    var renderData = [];

    var ind = 0;

    fields = this.orderFields(fields, module);

    fields.forEach((field, key) => {
      row.push(this.renderField(prefix, module, field, key));
      ind++;
      if (ind == 2) {
        renderData.push(this.renderRow(row, key));
        row = [];
        ind = 0;
      }
    });
    if (ind != 0) {
      renderData.push(this.renderRow(row, "last"));
    }
    return (
      <div className="massUpdateContainer pageContainer">
        <div className="viewActionPanel">
          <div className="viewActionPanelButtons">
            {changed && (
                <React.Fragment>
              <ButtonFloat
                variant="text"
                onClick={() => sAction.removeChangesMassUpdate(prefix)}
              >
              <div className={"actionPanelButtonIcon " + "icon-undo"} />
              {sAction.translate("LBL_RETURN_CHANGES")}
              </ButtonFloat>
              <div
              className="viewPanelButtonsDelimiter"
            />
            </React.Fragment>
            )}
            <ButtonFloat
              variant="text"
              className="hoverGreen"
              onClick={() => sAction.saveMassUpdate(prefix)}
            >
              <div className={"actionPanelButtonIcon " + "icon-saveIcon"} />
              {sAction.translate("LBL_MASSUPDATE_CONFIRM")}
            </ButtonFloat>
            <div
              className="viewPanelButtonsDelimiter"
            />
            <ButtonFloat
              variant="text"
              className="hoverRed"
              onClick={() => sAction.closeRightPanel()}
            >
              <div className={"actionPanelButtonIcon " + "icon-close"} />
              {sAction.translate("LBL_MASSUPDATE_CANCEL")}
            </ButtonFloat>
            <div
              className="viewPanelButtonsDelimiter"
            />
          </div>
        </div>
        <div className="muDetailViewContainer detailViewContainer">
          <div className="detailCard">
            <div className="header">
              <div className="title">
                {sAction.translate("LBL_MASS_UPDATE_TITLE")}
              </div>
            </div>
            <div className="detailViewTabContent">
              <div className="muDetailViewTabContentBlok">{renderData}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
