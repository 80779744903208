export default function routeKanban() {

    this.rest.post("getKanbanData", {module: "acm_tescan_orders"}, (data) => {

        if(data.status){
            const prefix = "view";
            this.dsAdd('set', prefix, {});
            this.dsAdd('set', prefix+"/config", data.message.config);
            this.dsAdd('set', prefix+"/data", data.message.data);
            this.dsAdd('set', prefix+"/filters", data.message.filters);
            this.dsAdd('set', 'conf/load', false);
            this.dsProcess();
        }
    });

    



    
    
}