export default function listViewCount(prefix){

    this.load(); // aktvuje načítání
    console.log(prefix)
    const list = this.dataGet(prefix); // získáme data z aktuálního listview
    const savedSearch = list.get("actSavedSearch") == "" ? null : list.get("actSavedSearch")
    const module = list.modul;
    const searchData = this.reloadListParams({list,prefix,savedSearch,offset:0,page:1});
    if (prefix === 'view') {
        searchData.isNormalList = true;
      }
    searchData.recordsCount = true
    searchData.function = { 
        id: {
            field: "id",
            function:"count",
            module: module
        }
    }
    searchData.columns = [
        {
            field: "id",
            function: "count",
            module: module,
            width:"100%",
        }
    ]
    searchData.order =  [
        {
            field:"id",
            sort:"DESC",
            module:module,
        }
    ]
    this.rest.post("list/" + module, searchData, data => {
        this.unLoad();
        const recordsCount = data.records[0].id;
        this.dataSet(prefix+"/rowTotalCount",parseInt(recordsCount));
    })
}