import detailDefault from "../../detailDefault";
export default class detailacm_tescan_orders extends detailDefault{
    load(sAction,data){
        const parentId = sAction.dataGet(data.prefix+"/fields/accounts_acm_tescan_orders_1_name/def/id_value");
        if(parentId){
            
            this.setAccFilters(sAction, data, parentId);

        }

        this.checkEditableFields(sAction, data);

    }
    update(sAction,data){
        switch(data.field){
            case "ct_study_id":
                if(data.value.length > 0){
                    this.checkCtStudyId(sAction, data);
                }
                break;
            case "ct_series_id":
                if(data.value.length > 0){
                    this.checkCtSeriesId(sAction, data);
                }
                break;
            case "accounts_acm_tescan_orders_1accounts_ida":
                    if(data.prefix !== "rightPanel/data"){
                        //hotfix
                        this.setAccFilters(sAction, data, data.value.id);
                        this.getContactFromAcc(sAction, data, data.value.id);   
                    }
                break;
        }
    }

    checkCtStudyId(sAction, data){

        const params = {
            id : sAction.dataGet(data.prefix+"/id"),
            table : "acm_tescan_orders",
            field : "ct_study_id",
            value : data.value,
        }

        sAction.rest.post("checkFieldDuplicity", params, function(resp) {
            if(resp.status){
                let result = resp.message.data;
                if(result.duplicate){

                    

                    let dupMsg = sAction.translate("LBL_DUPLICITIES_CT_ID", "acm_tescan_orders") + " :<br>"+result.records.map(
                            rec => "<a target='_blank' href='/#detail/acm_tescan_orders/"+rec.id+"'>"+rec.name+"</a>"
                        ).join("<br>");
                    sAction.alert(dupMsg);
                }
            }
          });
    }

    checkCtSeriesId(sAction, data){

        const params = {
            id : sAction.dataGet(data.prefix+"/id"),
            table : "acm_tescan_orders",
            field : "ct_series_id",
            value : data.value,
        }

        sAction.rest.post("checkFieldDuplicity", params, function(resp) {
            if(resp.status){
                let result = resp.message.data;
                if(result.duplicate){
                    let dupMsg = sAction.translate("LBL_DUPLICITIES_CT_SERIES_ID", "acm_tescan_orders") + " :<br>"+result.records.map(
                            rec => "<a target='_blank' href='/#detail/acm_tescan_orders/"+rec.id+"'>"+rec.name+"</a>"
                        ).join("<br>");
                    sAction.alert(dupMsg);
                }
            }
          });
    }

    setAccFilters(sAction, data, parentId){

        let filter = "";
        let filterContact = "";

        if(parentId){
            filter = {
                parent: {
                    operandType: "relate",
                    parentId: parentId,
                    module: "Accounts",
                    relationship: ["acm_doctors_accounts"],
                    name: "acm_doctors_accounts_name",
                    relName: "acm_doctors_accounts",
                    type: "eq"
                }
            }
            
            filterContact = {
                parent: {
                    operandType: "relate",
                    parentId: parentId,
                    module: "Accounts",
                    relationship: ["accounts"],
                    name: "account_name",
                    relName: "contacts",
                    type: "eq"
                },
                role: {
                    field: "role",
                    value: "main",
                    type: "eq"
                },

            }
        }
        sAction.dataSet(data.prefix+"/fields/acm_doctors_acm_tescan_orders_1_name/def/defaultFilter", filter);
        sAction.dataSet(data.prefix+"/fields/contacts_acm_tescan_orders_1_name/def/defaultFilter", filterContact);
        
    }

    getContactFromAcc(sAction, data, parentId){
        if(parentId){
            sAction.rest.post("getMainContactFromAcc", {account: parentId}, function(resp) {
                if(resp.status){
                    let result = resp.message.data;
                    if(result.contact_id){
                        sAction.dsClear();
                        sAction.dsAdd("set", data.prefix+"/fields/contacts_acm_tescan_orders_1_name/value", result.contact_name);
                        sAction.dsAdd("set", data.prefix+"/fields/contacts_acm_tescan_orders_1_name/def/id_value", result.contact_id);
                        sAction.dsAdd("set", data.prefix+"/changes/fields/contacts_acm_tescan_orders_1contacts_ida", result.contact_id);

                        sAction.dsProcess();
                    }
                }
            });
        }
    }

    checkEditableFields(sAction, data){

        let editableFields = [];

        let ss = sAction.dataGet(data.prefix+"/customData/ss");
        if(!ss){
            return;
        }
        ss.forEach(rec => {

            if(rec == "891bbe49-3089-29f5-99a5-60f7c351e486"){
                //zakazkovy uzivatel
                editableFields = [
                    "status", "status_prod", "description", "size", "urls",
                    "user_manufacture",
                    "user_manufacture_prepare",
                    "user_models",
                    "user_order_prepare",
                    "user_prodcut_verify",
                    "user_product_check",
                    "user_product_completion",
                    "user_product_design",
                    "user_reconstruct_design",
                    "user_reconstruct_verify",
                    "user_description",
                    "user_packing",
                    "output_check",
                    "user_sending",
                    "thickness",
                ]
            }

        })

        if(editableFields.length == 0){
            return;
        }

        sAction.dsClear();

        sAction.dataGet(data.prefix+"/fields").forEach(field => {
            if(!editableFields.includes(field.name)){
                sAction.dsAdd("set", data.prefix+"/fields/"+field.name+"/def/readonly", true);
            }
        });
        

        sAction.dsProcess();
    }
}