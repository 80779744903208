import sAction from "../index";

export default function saveRecordBase(paramData, callback) {
  this.doRoute = true;
  const module = paramData.module;
  var fields = this.dataGet(paramData.prefix + "/fields");
  var wrongFields = [];
  const imagesToDelete = this.dataGet("view/imageActions/deleteOnSave");

  // promazani zmen v local storage
  sAction.removeChangesFromLS(module,sAction.dataGet('conf/user/id'))

  // _______________________________________________ONLY QUOTES
  if(module == "Meetings" || module == "Calls"){
    this.dataSet(paramData.prefix + "/changes/invitees", this.dataGet(paramData.prefix + "/customData/invitees/relatedInv"));
  }
  else if (module == "Quotes") {
    //handeling product lines on Quotes
    let quoteData = this.dataGet(paramData.prefix + "/customData");
    var prodData = quoteData.get("productData").toJS();
    const sums = this.calcSums(paramData.prefix + "/customData/productData", {
      type: "total",
      data: prodData
    });
    const duplicateId = quoteData.duplicateId;
    const type = quoteData.type;
    var customData = { productData: prodData,  total_tax : sums.total_tax, total_discount : sums.total_discount, total: sums.total, duplicateId, type };
    this.dataSet(paramData.prefix + "/changes/customData", customData);
  }
  else if(module == "Opportunities"){
    this.dataSet(paramData.prefix + "/changes/customData/productData", this.dataGet(paramData.prefix + "/customData/productData"));
  }
  // _______________________________________________ONLY QUOTES END
    let sendToCrm = true;
    // _______________________________________________CUSTOM LINES
    let customLinesData = this.dataGet(paramData.prefix + '/customData');
    if (customLinesData && customLinesData.get && customLinesData.get('customLines') && customLinesData.get('customLines').get('lines')) {
      // CONVERT TO CHANGES
      this.dataSet(paramData.prefix + "/changes/customData/customLines", customLinesData.get('customLines').get('lines'));
      // CHECK IF REQUIRED
      let requiredFields = [];
      // Ziska povinna pole
      customLinesData.get('customLines').get('def').get('fields').toJS().forEachObject((field, key) => {
        if (customLinesData.get('customLines').get('moduleData').get(field).get('required') === true) {
          requiredFields.push(field);
        }
      });
      // Zjisti jeslti nejake povinne pole je prazdne
      if (Array.isArray(requiredFields) && requiredFields.length) {
        customLinesData.get('customLines').get('lines').toJS().forEachObject((line, key) => {
          requiredFields.forEach((field) => {
            if (!line[field] && line['deleted'] !== 1 && line['deleted'] !== '1') {
              this.dsClear();
              this.dsAdd('set', paramData.prefix + '/customData/customLines/showError', true);
              this.dsProcess();
              sendToCrm = false;
            }
          });
        });
      }
    }
    // _______________________________________________CUSTOM LINES END

  const propperCanSave = this.dataGet("view/customData/canSave");
  let allowSave = true
  if (propperCanSave) {
    propperCanSave.forEach((value, key) => {
      if (value === false) {
        allowSave = false;
      }
    });
  }

  if (paramData.canSave === true && allowSave === true) {
      if (this.custom("beforeSave", paramData)) {
            // Zakomentovani umozni kontrolu required poli v radcich.
            // var sendToCrm = true;
          let boundModules = this.dataGet(paramData.prefix + '/boundModules');
          console.log('DANIEL', boundModules);
          console.log('DANIEL', paramData);
          if (boundModules && !paramData.id) {
              let i = 1;
              console.log('DANIEL: ' + i++);
              this.dsClear();
              this.dsAdd("set",paramData.prefix + '/changes/customData/boundModules', {});
              console.log('DANIEL: ' + i++);
              boundModules.toJS().forEachObject((module, index) => {
                  console.log('DANIEL: ' + i++);
                  let way = paramData.prefix + '/boundModules/' + index;
                  /**************** PROJIT KAZDY MODUL JESTLI NEMA PRAZDNE REQUIRED POLE */
                  if (module.customData.checked === true) {
                      console.log('DANIEL: ' + i++);
                      let ignoredFieldsBound = ['id'];
                      let visibleFieldsBound = [];
                      const tabsBound = module.tabs;
                      tabsBound.forEach((tab, index) => {
                          console.log('DANIEL: ' + i++);
                          if (tab.hidden) {
                              tab.rows.forEach((row, index) => {
                                  row.forEach((line, index) => {
                                      ignoredFieldsBound.push(line);
                                  });
                              });
                          }
                          else{
                              tab.rows.forEach((row, index) => {
                                  if(!Array.isArray(row)){
                                      return;
                                  }
                                  row.forEach((line, index) => {
                                      visibleFieldsBound.push(line);
                                  });
                              });
                          }
                      });

                      module.fields.forEachObject(data => {
                          if (!data.def) return;
                          if (ignoredFieldsBound.includes(data.name)) {
                              return;
                          }
                          if(!visibleFieldsBound.includes(data.name)){
                              return;
                          }
                          if (
                              data.def.required === true &&
                              (data.value === "" || data.value === null)
                          ) {
                              sendToCrm = false;
                              this.dsAdd("set",way + "/fields/" + data.name + "/customClass", 'error');
                          }else if(
                              data.def.required === true &&
                              (data.value != "" || data.value != undefined) &&
                              data.customClass == "error"
                          ){
                              this.dsAdd("set",way + "/fields/" + data.name + "/customClass", '');
                          }
                      });
                      // Prida do changes data o modulu
                      console.log('DANIEL: ' + i++, sendToCrm);
                      if (sendToCrm) {
                          console.log('DANIEL: ' + i++, module);
                          this.dsAdd("set",paramData.prefix + '/changes/customData/boundModules/' + module.module, module.changes);
                          let emails2 = this.customFieldEmail(paramData.prefix + '/boundModules/' + index);
                          if (emails2) {
                              this.dsAdd("set",paramData.prefix + '/changes/customData/boundModules/' + module.module + '/fields/email1', emails2);
                          }
                      }
                  }
                  /***********************************************************************/
              });
              this.dsProcess();
              console.log('AHOJ');
            }
        //ziskani poli ve skrytych tabech pro nasledene ignorovani pri overeni povinnych poli
          var editFields = this.dataGet(paramData.prefix + "/changes");


          const emails = this.customFieldEmail(paramData.prefix);
          if (emails) {
              editFields = editFields.setIn(["fields", "email1"], emails);
          }
        let ignoredFields = ["id"];
        const visibleFields = [];
        const tabs = this.dataGet(paramData.prefix + "/tabs");
        console.log("ted");
        tabs.forEach((tab, index) => {
            tab.rows.forEach((row, index) => {
              row.forEach((line, index) => {
                if (tab.hidden) {
                ignoredFields.push(line);
                }else{
                  const def = fields.getIn([line,"def"]);
                  if(def!== undefined){
                    if(def.get("type") == "address"){
                      const groupName = def.get("group")
                      visibleFields.push(groupName+"_street");
                      visibleFields.push(groupName+"_city");
                      visibleFields.push(groupName+"_postalcode");
                      visibleFields.push(groupName+"_state");
                      visibleFields.push(groupName+"_kraj");
                      visibleFields.push(groupName+"_country");
                    }else{
                      visibleFields.push(line)
                    }
                  }
                  
                }
              });
            });
        });

        const hiddenFields = this.dataGet(paramData.prefix+"/hiddenFields");
        hiddenFields.forEach(field => {
          ignoredFields.push(field);
        })

        fields.valueSeq().forEach(data => {
          if (!data.def) return;
          if (ignoredFields.includes(data.name)) {
            return;
          }
          if(!visibleFields.includes(data.name)){
            return;
          }
          if (
            data.def.get("required") === true &&
            (data.value === "" || data.value === null)
          ) {
            wrongFields.push({name:data.name,class:"error"})
            sendToCrm = false;
          }else if(
            data.def.get("required") === true && 
            (data.value != "" || data.value != undefined) && 
            data.customClass == "error"
          ){
            
            wrongFields.push({name:data.name,class:""})
          }
        });
        if (sendToCrm === false) {
          console.log(visibleFields);
          console.log(wrongFields)
          this.dsClear();
          wrongFields.forEach(fieldName => {
            this.dsAdd("set",paramData.prefix+"/fields/"+fieldName.name+"/customClass",fieldName.class);
          });
          this.dsProcess();
          const data = {
            state: false,
            msg: "Nejsou vyplněny všechny povinné údaje"
          };
          callback(data);
        } else {
          const module = paramData.module;
          var src = module+"/";
          var id = paramData.id;
          if (id !== undefined && id !== null) {
            src += id
          }
          this.load();
          var self = this;

          if (module == 'Users' && id && id == this.dataGet('conf/user/id') && editFields.get('fields')) {
            editFields.get('fields').toJS().forEachObject((value, key) => {
              if (key == 'records_per_page') {
                this.dsClear();
                this.dsAdd("set",'conf/user/records_per_page', value);
                this.dsProcess();
              }
            });
          }
          this.rest.post("set/" + src, editFields, msg => {
            var data = {
              state: true,
              data: msg
            };
            if(module == "Quotes"){
              data["cancelPrevisouRequest"] = false
              self.rest.post("recalcTopRecent", {}, function(resp){
              },true);
            }
            callback(data);
          });

          if (imagesToDelete) {
            this.rest.delete("deleteImages/", imagesToDelete.toJS(), false, false)
          }

        }
      }
    } else {
      const propperCanSaveText = this.dataGet("view/customData/canSaveText");
      let msg = this.translate('LBL_RECORD_CANNOT_BE_SAVED_NOW');
      if (propperCanSaveText){
        propperCanSaveText.forEach((values,keys)=>{
          msg += '<br> '+values;
        })
      }
      const data = {
        state: false,
        msg: msg,
      };
      callback(data);
    }
  }
