export default function acmTescanOrdersEmail(params){
    const emails = [];
    
    console.log("EMMM", params);
    
    const email = params.emails.join(", ");

    const data = {
        parent_type: params.module,
        parent_id: params.id,
        parent_name: params.name,
        to_addrs: email,
        emailTemplate : "",
        keepSelf: true,
    };
    
    this.openEmailPopup(data);
}