export default function convertRecord(data) {
    const params = {action: data.convertName, record : data.id};
    this.load();
    this.rest.post('customAction', params, resp => {
        if(resp.errCode == "OK"){
            this.href("#detail/" + resp.newModule + "/"+resp.record);
        } else{
            this.unLoad();
            this.alert(resp.errMsg);
        }
    });
}
