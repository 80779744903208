import React from "react";
import PureComponent from "../pure";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import AcmKanbanItem from "./AcmKanbanItem";
import MultiSelect from '../formElements/MultiSelect';
import Button from "../formElements/Button";

import sAction from "sAction";

class AcmKanban extends React.Component {

    constructor(props){
        super(props);

        const data = this.props.data.toJS();
        const config = data.config;
        const filters = data.filters;

        this.state = {
            config : config,
            module: "acm_tescan_orders",
            filters : filters,
        }
    }

    filterSet(field, value){

        let filtPom = this.state.filters;

        filtPom[field].selected = value;

        this.setState({
            filters: filtPom
        })

    }

    onDragEnd(res){
        if(!res.source || !res.destination){
            return;
        }
        let acl = sAction.getAccess("acm_tescan_orders");
        if(acl?.edit !== true){
            return;
        }
        
        
        let pomSource = res.source.droppableId.split("#");
        let pomDest = res.destination.droppableId.split("#");

        const prefix = this.props.way+"/customData";
        

        const item = sAction.dataGet(prefix+"/data/"+pomSource[1]+"/"+pomSource[0]+"/"+res.source.index);

        if(pomSource[1] !== pomDest[1] || pomSource[0] !== pomDest[0]){

            sAction.dsClear();
            sAction.dsAdd("delete", prefix+"/data/"+pomSource[1]+"/"+pomSource[0], res.source.index);
            sAction.dsAdd("add", prefix+"/data/"+pomDest[1]+"/"+pomDest[0], item);
            sAction.dsProcess();

            let changes = {};
            changes[this.state.config.primary.field] = pomDest[0];
            changes[this.state.config.secondary.field] = pomDest[1];

            sAction.load();
            sAction.rest.post("setKanbanItem", {
                    module: this.state.module,
                    item: item,
                    changes: changes,
            
            }, (resp) => {
                
                if(!resp.status){
                    var list = sAction.dataGet(prefix+"/data/"+pomDest[1]+"/"+pomDest[0]);
                    list = list.delete(list.size - 1);

                    sAction.dsClear();
                    //sAction.dsAdd("delete", prefix+"/data/"+pomSource[1]+"/"+pomSource[0], res.source.index);
                    sAction.dsAdd("set", prefix+"/data/"+pomDest[1]+"/"+pomDest[0], list);
                    sAction.dsAdd("add", prefix+"/data/"+pomSource[1]+"/"+pomSource[0], item);
                    sAction.dsProcess();

                    sAction.error(sAction.translate(resp.errorMessage, "acm_tescan_orders"));

                }
                else{
                    //refresh dat
                    var list = sAction.dataGet(prefix+"/data/"+pomDest[1]+"/"+pomDest[0]);

                    sAction.dsClear();
                    sAction.dsAdd("set", prefix+"/data/"+pomDest[1]+"/"+pomDest[0]+"/"+(list.size - 1)+"/assigned_user_id", resp.message.data.assigned_user_id);
                    sAction.dsAdd("set", prefix+"/data/"+pomDest[1]+"/"+pomDest[0]+"/"+(list.size - 1)+"/responsible_user", resp.message.data.responsible_user);
                    sAction.dsAdd("set", prefix+"/data/"+pomDest[1]+"/"+pomDest[0]+"/"+(list.size - 1)+"/responsible_user_id", resp.message.data.responsible_user_id);
                    
                    sAction.dsAdd("set", prefix+"/data/"+pomDest[1]+"/"+pomDest[0]+"/"+(list.size - 1)+"/"+this.state.config.primary.field, pomDest[0]);
                    sAction.dsAdd("set", prefix+"/data/"+pomDest[1]+"/"+pomDest[0]+"/"+(list.size - 1)+"/"+this.state.config.secondary.field, pomDest[1]);

                    sAction.dsProcess();
                }
                sAction.unLoad();
            });
        }
    }

    clearFilters(){
        let filters = this.state.filters;
        filters.forEachObject((fVal, fKey) => {
            fVal.selected = [];
        });

        this.setState({"filters" : filters});
    }

    render(){

        const secondary = this.state.config.secondary;
        const primary = this.state.config.primary;
        
        let activeFilters = [];

        this.state.filters.forEachObject((fVal, fKey) => {

            //TODO dalsi typy
            switch(fVal.type){
                case "enum":
                case "enum_custom":
                    if(fVal.selected.length > 0){
                        activeFilters[fVal.field] = fVal.selected;
                    }
                    break;
                
            }
        });


        let kanbanRows = [];
        const data = this.props.data.get("data");
        const colorList = this.state.config.colorList;
        const colorField = this.state.config.colorField;

        secondary.keys.forEach((secKey) => {
            let secVal = secondary.values[secKey];
            let kanbanCols = [];

            let dataRow = data[secKey];
            let visible = secondary.visibility[secKey];

            if(visible){
                primary.keys.forEach((primKey) => {
                    let primVal = primary.values[primKey];
                    let dataCol = dataRow[primKey];

                    let itemComp = [];
                    let itemCnt = 0;

                    dataCol.map(
                        (value, key) => {

                            let filteredOut = false;

                            activeFilters.forEachObject((fVals, fField) => {
                                if(!fVals.includes(value.get(fField))) {
                                    filteredOut = true;
                                    return;
                                }
                            });

                            if(!filteredOut){

                                let color = "#FFF";
                                let colorVal = value.get(colorField);
                                if(colorList[colorVal]){
                                    color = colorList[colorVal];
                                }

                                itemComp.push(
                                    <AcmKanbanItem 
                                        key={value.get("id")} 
                                        index={parseInt(key)} 
                                        data={value}
                                        module={this.state.module}
                                        color={color}
                                        />
                                );
                            }

                            itemCnt = itemComp.length;
                        }
                    )

                    kanbanCols.push(
                        <div className="acmKanbanColumn" key={primKey}>
                        <div className="acmKanbanColumnTitle" title={primVal+" ("+itemCnt+")"}>{primVal} ({itemCnt})</div>
                        <Droppable droppableId={primKey+"#"+secKey}>
                        {(provided) => (
                            <div className="acmKanbanDropArea"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {itemComp}
                                {provided.placeholder}
                            </div>
                        )}
        
                        </Droppable>
                        </div>
                    );
                });
            }
            kanbanRows.push(
                <div className="acmKanbanRow" key={secKey}>
                    <div className="acmKanbanRowTitle">
                        <span className="icon-plusExpand expandRowIcon"
                            onClick={() => {
                                let curConfig = this.state.config;
                                curConfig.secondary.visibility[secKey] = !curConfig.secondary.visibility[secKey];
                                this.setState({config: curConfig})
                            }}                        
                         />
                        {secVal}
                    </div>
                    <div className="acmKanbanRowContainer">
                        {kanbanCols}
                    </div>
                </div>
            );
        });

        let filterComp = [];

        this.state.filters.forEachObject((filter, filterKey) => {
            switch(filter.type){
                case "enum":
                case "enum_custom":
                    let filtOptions = [];
                    filter.values.forEachObject((filtVal, filtKey) => {
                        filtOptions.push({value : filtKey, label: filtVal});
                    })
                    filterComp.push(
                        <div className="acmKanbanFilterItem" key={filter.field}>
                            <div className="acmKanbanFilterLabel">{sAction.translate(filter.label, filter.labelModule)}</div>
                            <MultiSelect 
                                open="false"
                                name={filter.field}
                                id={filter.field}
                                options={filtOptions}
                                defaultValue={filter.selected}
                                onBlur={(val) => {this.filterSet(filterKey, val)}}
                            />
                        </div>
                    );
                    break;
            }
        })
            
        return <div className="acmKanbanContainer">
            <div className="acmKanbanFilterContainer">
                {filterComp}
                <div>
                <Button 
                    className="filledButton redBtn"
                    onClick={() => this.clearFilters()}>
                    {sAction.translate("LBL_CLEAR_FILTERS", "acm_tescan_orders")}
                </Button>
                </div>
            </div>
            <DragDropContext
                onDragEnd = { (res) => {this.onDragEnd(res)}}
            >
            {kanbanRows}

            </DragDropContext>
        </div>;

    }

}
export default AcmKanban;