import detailDefault from "../../detailDefault";

export default class detailacm_doctors extends detailDefault {
    update (sAction, data) {
        switch (data.field) {
            case 'last_name':
                data[data.field] = data.value;
                this.checkDuplicity(sAction, data);
                break;
        }
    }

    checkDuplicity(sAction, data) {
        if (data.last_name === undefined) {
            data.last_name = sAction.dataGet(data.prefix + '/fields/last_name/value');
        }

        if (!data.last_name) {
            return;
        }

        let searchData = {};
        searchData.recordsCount = true;
        searchData.filter = {
            operator: 'and',
            operands: [
                {
                    field: 'last_name',
                    type: 'eq',
                    value: data.last_name,
                },
            ],
        };
        searchData.function = {
            id: {
                field: "id",
                function:"count",
                module: 'acm_doctors'
            }
        }
        searchData.columns = [
            {
                field: "id",
                function: "count",
                module: 'acm_doctors',
                width:"100%",
            }
        ]
        searchData.order =  [
            {
                field:"id",
                sort:"DESC",
                module:'acm_doctors',
            }
        ]
        sAction.rest.post("list/acm_doctors", searchData, returnData => {
            if (returnData?.records[0].id && returnData?.records[0].id != 0) {
                sAction.error(sAction.translate('LBL_DOCTOR_ALREADY_EXISTS', 'acm_doctors'));
            }
        }, false);
    }
}
