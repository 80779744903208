import detailDefault from "../../detailDefault";

export default class detailacm_patients extends detailDefault {
    update(sAction, data) {
        console.log('DANIEL UDPATE', data);
        switch (data.field) {
            case 'first_name':
            case 'last_name':
                data[data.field] = data.value;
                this.createPID(sAction, data);
                break;
            case 'pid':
                data[data.field] = data.value;
                this.checkDuplicity(sAction, data);
                break;
        }
    }

    createPID(sAction, data) {
        sAction.dsClear();
        if (!sAction.dataGet(data.prefix + '/customData')) {
            sAction.dsAdd('set', data.prefix + '/customData', {});
        }
        sAction.dsAdd('set', data.prefix + '/customData/canSave', [false]);
        sAction.dsAdd('set', data.prefix + '/customData/canSaveText', [sAction.translate('LBL_WAIT_FOR_PID_VERIFY')]);
        sAction.dsProcess();
        if (data.last_name === undefined) {
            data.last_name = sAction.dataGet(data.prefix + '/fields/last_name/value');
        }
        if (data.first_name === undefined) {
            data.first_name = sAction.dataGet(data.prefix + '/fields/first_name/value');
        }
        if (!data.first_name || !data.last_name) {
            return;
        }

        let pid = data.last_name.substr(0, 3) + data.first_name.substr(0, 3);
        pid = pid.toUpperCase();
        //odstraneni diakritiky
        pid = pid.normalize("NFD").replace(/\p{Diacritic}/gu, "");

        sAction.dsClear();
        sAction.dsAdd('set', data.prefix + '/fields/pid/value', pid);
        sAction.dsAdd('set', data.prefix + '/changes/fields/pid', pid);
        sAction.dsProcess();
        data.value = pid;
        data.pid = pid;
        this.checkDuplicity(sAction, data);
    }

    checkDuplicity(sAction, data) {
        if (data.pid === undefined) {
            data.pid = sAction.dataGet(data.prefix + '/fields/pid/value');
        }

        if (!data.pid) {
            return;
        }

        let searchData = {};
        searchData.recordsCount = true;
        searchData.filter = {
            operator: 'and',
            operands: [
                {
                    field: 'pid',
                    type: 'eq',
                    value: data.pid,
                },
            ],
        };
        searchData.function = {
            id: {
                field: "id",
                function:"count",
                module: 'acm_patients'
            }
        }
        searchData.columns = [
            {
                field: "id",
                function: "count",
                module: 'acm_patients',
                width:"100%",
            }
        ]
        searchData.order =  [
            {
                field:"id",
                sort:"DESC",
                module:'acm_patients',
            }
        ]
        sAction.rest.post("list/acm_patients", searchData, returnData => {
            if (returnData?.records[0].id && returnData?.records[0].id != 0) {
                sAction.error(sAction.translate('LBL_PATIENT_ALREADY_EXISTS', 'acm_patients'));
            }
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/customData/canSave', []);
            sAction.dsAdd('set', data.prefix + '/customData/canSaveText', []);
            sAction.dsProcess();
        }, false);
    }
}
