import {List} from "immutable";

export default function selectLook(val,prefix){

  let category = this.dataGet(prefix+"/category");
  if(category === "kanban"){
    //prepiname z kanbanu, je treba prenacist cely list, aby se obnovyly filtry filtery
    this.routeListView(false);
    return;
  };
  
  this.dsClear();
  
  if(val !== "kanban"){
    //pokud se prepiname do kanbanu, nic nemazat
    this.dsAdd('set', prefix + '/actFiltering', false);
    this.dsAdd('set', prefix + '/filter', List());
    this.dsAdd('set', prefix + '/orderBy', "");
  }

  this.dsProcess();
  this.reloadList(0,1,prefix,val);
}