export default function openDefaulListViewEdit(way) {
  const data = this.dataGet(way)
  const module = data.modul
  this.load();

  const params = {
    module: module
  }
  var parent = "";
  if(data.type == "subpanel"){
    parent = this.dataGet(way+"/../../../module")
    params["subpanel"] = true;
    params["parent"] = parent;
  }

  this.rest.post("openDefaultListView", params, (defsData) => {
    this.dsClear();
    this.dsAdd("set", "conf/popup/show", true);
    this.dsAdd("set", "conf/popup/content", "defaultListViewEdit");
    this.dsAdd("set", "conf/popup/data/columns", {}, "reportWindowColumns");

    var fields = {
      fields: [],
      relate: [],
    };
    const active = {};
    const hidden = {};
    defsData.fields.forEachObject((field, key) => {
      if (field != null) {
        if (field.type == "relate" || field.type == "link") {
          if (field.searchable == true) {
            fields.relate.push(field);
          }
        } else {
          fields.fields.push(field);
        }
      }

      const k = key.toLowerCase();

      // let def = defsData[k];
      if (field && field.type !== "link") {
        field["show"] = true;
        field["fieldModule"] = module;
        if (field["default"] && defsData.active.length == 0) {
          /*  this.dsAdd(
                'add',
                'conf/popup/data/columns/active',
                { name: k, def: field },
                'reportWindowColumn'
            ); */
          active[k] = field;
        } else {
          /*  this.dsAdd(
                'add',
                'conf/popup/data/columns/hidden',
                { name: k, def: field },
                'reportWindowColumn'
            ); */
          hidden[k] = field;
        }
      }
    });
    if (defsData.active.length != 0) {
      defsData.active.forEach((field) => {
        if (hidden[field.field] != undefined) {
          const fieldDef = hidden[field.field]
          fieldDef["width"] = field.width
          active[field.field] = fieldDef;
          delete hidden[field.field];
        }
      });
    }

    hidden.forEachObject((def, name) => {
      this.dsAdd(
        "add",
        "conf/popup/data/columns/hidden",
        { name: name, def: def },
        "reportWindowColumn"
      );
    });
    active.forEachObject((def, name) => {
      
      this.dsAdd(
        "add",
        "conf/popup/data/columns/active",
        { name: name, def: def },
        "reportWindowColumn"
      );
    });

    //    this.dsAdd("set", "reportWindow/metadata", {}, "reportWindowMetadata");
    this.dsAdd("set", "conf/popup/data/header", this.translate("LBL_LISTVIEW_EDIT"));
    this.dsAdd("set", "conf/popup/data/module", module);
    this.dsAdd("set", "conf/popup/data/fields", fields.fields);
    this.dsAdd("set", "conf/popup/data/relate", fields.relate);
    if(data.type == "subpanel"){
      this.dsAdd("set", "conf/popup/data/subpanel", true);
      this.dsAdd("set", "conf/popup/data/parent", parent);
    }
    this.dsAdd("set", "conf/popup/data/activeRelate", [
      { main: true, module: module },
    ]);
    this.dsProcess();
    this.unLoad();
  });
}
