import React from "react";
import moment from 'moment';
import PureComponent from "../../pure";
import Select from '../../formElements/Select';
import Button from "../../formElements/Button";

import  sAction  from "sAction";
import Loader from "../../loader";


export default class TescanOrdersSelectTemplate extends PureComponent {
    constructor(props){
        super(props);
        let date_quote_from = new Date();
        let date_quote_expected_closed = new Date(moment().add(30, 'days').format("YYYY-MM-DD"));

        let febPom = "-02-28";
        if(moment().isLeapYear()){
            febPom = "-02-29";
        }

        const pomDate = new Date(moment().format("YYYY"+febPom));
        
        if(date_quote_from < pomDate && date_quote_expected_closed > pomDate){
            date_quote_expected_closed = pomDate;
        }

        this.state = {
            template: "",
            trans_period : 0,
            date_quote_from : date_quote_from,
            date_quote_expected_closed : date_quote_expected_closed,
            language : '',
            printType : '',
            loaded: true
        }
    }

    componentDidMount() {
    }

    render(){
        if (!this.state.loaded) return <Loader />;
        const params = this.props.data;

        const prefix = params.get('prefix');

        var druh = sAction.dataGet(prefix+"/fields/code_type/value");

        var templateOptions = [];
        var typeOptions = [];

        sAction.app_strings['language_list'].forEachObject((val, key) => {
            templateOptions.push(
              {value:key, label: val},
            );
        })
        sAction.app_strings['toreders_template_type_list'].forEachObject((val, key) => {
            typeOptions.push(
              {value:key, label: val},
            );
        })


        return <div>
        <div className="acmPopupHeader">{sAction.translate("LBL_PRINT_TESCAN_ORDER", "acm_tescan_orders")}</div>
        <div className="icon-Close popupCloseIcon"></div>

        <div className="templateCreatorFormContainer templateCreatorForm">
            <div className="templateCreatorFormRow">
                {sAction.translate("LBL_PRINT_THIS_TESCAN_ORDER", "acm_tescan_orders")}
            </div>

            <div className="templateCreatorFormRow">
                &nbsp;
            </div>

            <div className="templateCreatorFormRow">
                <div className="quoteTemplNameLbl">
                    {sAction.translate("LBL_LOGIN_LANGUAGE")}:
                </div>
                <Select
                  open={false}
                  name="template_name"
                  options={templateOptions}
                  onChange={(ev) => this.setState({language : ev.target.value})}
                />
            </div>
            <div className="templateCreatorFormRow">
                <div className="quoteTemplNameLbl">
                    {sAction.translate("LBL_ORDERS_PRINT_TYPE_SELECT_LABEL",'acm_tescan_orders')}:
                </div>
                <Select
                  open={false}
                  name="type"
                  options={typeOptions}
                  onChange={(ev) => this.setState({printType : ev.target.value})}
                />
            </div>
        </div>
        <div className="acmPopupButtons">
            <Button className="hoverGreen" onClick={() => sAction.printWordPrepare({id:params.get('id'),module:params.get('module'),name:params.get('number'), type: druh, lang:this.state.language, printType:this.state.printType})}>
                <div className={"icon-print calHeaderIcon"} ></div>
                {sAction.translate("LBL_PRINT_WORD", "acm_contracts")}
            </Button>
            <Button className="hoverRed" onClick={() => sAction.popupHide()}>
                <div className={"icon-close calHeaderIcon"} ></div>
                {sAction.translate("LBL_CANCEL_ACTION")}
            </Button>
        </div>
    </div>;
    }
}
