import React from "react";
import sAction from 'sAction';

function htmlUnescape(str) {
  return str == null
    ? ""
    : str
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&");
}

export default class HTMLText extends React.Component {
  constructor(props) {
    super(props);
  }

  handleEditorChange = (e) => {
    this.save()
  }

  setValue = (value) => {
    // todo: tinyMCE se nemusi nacist/aktivovat hned, tohle docasne reseni, ale funkcni
    let setContent = setInterval(function () {
      try {
        tinyMCE.activeEditor.setContent(value);
        clearTimeout(setContent);
      } catch (e) {
      
      }
    }, 500);
  }

  save(param = {}) {
    if (this.props && this.props.onChange) {
      const value = tinyMCE.activeEditor.getContent();
      this.props.onChange(value);
    }
  }

  componentDidMount() {
    sAction.load();
    var tinymce_min = document.querySelector('script[src*="tinymce.min.js"]');

    if (tinymce_min) {
      this.init_tinymce();
    } else {
      const script = document.createElement("script");
      script.src = "build/tinymce/tinymce.min.js";
      document.body.appendChild(script);

      script.onload = () => {
        this.init_tinymce();
      }
    }
  }

  init_tinymce = () => {
    let language = sAction.getLanguage();
    language = (language && language.substring(0,2)) || "cs";

    const self = this;
    tinymce.init({
      selector: '#htmlEditorFormElements',
      language,
      relative_urls : false,
      remove_script_host : false,
      document_base_url : document.location.origin,
      setup: (ed) => {
        ed.on('change', (e) => {
          this.handleEditorChange(e);
        });
        ed.on('init', (e) => {
          sAction.unLoad();
        });

        if (this.props.refreshVariables !== undefined) {
          ed.ui.registry.addButton('customButton', {
            text: sAction.translate("LBL_REPLACE_VARIABLES","Emails"),
            icon: 'paste-text',
            onAction: function (_) {
              self.save();
              self.props.refreshVariables();
            }
          });
        }

      },
      menubar: 'edit view insert format tools table help',
      plugins: [
        'print preview fullpage paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link table charmap hr pagebreak nonbreaking advlist lists wordcount imagetools textpattern noneditable help charmap quickbars'
      ],
      toolbar:
        'undo restoredraft redo | bold italic underline | forecolor fontsizeselect | alignleft aligncenter alignright alignjustify | numlist bullist | fullscreen preview print | customButton',
      readonly: this.props.readonly ? 1 : 0
    });
  }

  componentWillUnmount() {
    document.getElementById("htmlEditorWrapper").innerHTML = "";
    //smazani tinymce tooltipu z <body>
    var elements = document.getElementsByClassName("tox-silver-sink");
    while(elements.length > 0){
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  render() {
    const {data, newRecord, readonly} = this.props;

    let value = this.props.defaultValue;
    if (newRecord || value === undefined || value === null) {
      value = "";
    }
    if (value) {
      value = htmlUnescape(value);
    }

    return (
      <div id="htmlEditorWrapper" className="w100">
        <textarea id="htmlEditorFormElements" defaultValue={value}></textarea>
      </div>
    );
  }
}
