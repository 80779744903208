import React from "react";
import PureComponent from "../pure";
import {Draggable} from "react-beautiful-dnd";

import sAction from "sAction";

class AcmKanbanItem extends PureComponent {

    render(){
        const color = this.props.color;

        const data = this.props.data;
        const id = data.get("id");
        const name = data.get("name");
        let code_type = data.get("code_type") ? data.get("code_type") : "";
        let pid = data.get("pid") ? data.get("pid") : "";
        let comm_lang = data.get("comm_lang") ? sAction.app_strings.comm_lang_list[data.get("comm_lang")] : "";

        let expIcon = <span onClick={() => window.open("#detail/"+this.props.module+"/"+id, "_blank")} className="kanbanExpand icon-expandIcon"></span>;
        return <Draggable draggableId={id} index={this.props.index} >
        {(provided, snapshot) => (
        <div className="acmKanbanItem"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            <div className="acmKanbanItemContainer" style={{"backgroundColor" : color}}>
                <div className = "acmKanbanItemTitleCont">
                    <span className="acmKanbanItemTitle">
                    {name}
                    </span>
                    {expIcon}
                    <div 
                        className="icon-Eye kanbanEye" 
                        id={"eye_"+id} 
                        onClick={() => {
                            sAction.popper({
                                selector: "#eye_"+id,
                                content: "kanbanItem",
                                data: data,
                                header: name,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "right",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                            });
                        }}
                    >
                    </div>
                </div>
                
                <div>{data.get("responsible_user")}</div>
                <div>{code_type+" ("+pid+") - "+comm_lang}</div>
            
            </div>
        </div>
        )}
        </Draggable>
        
    }

}
export default AcmKanbanItem;